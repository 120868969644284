import Header from '../components/Header';
import PageComponent from '../components/PageComponent';
import PageComponent2 from '../components/PageComponent2';
function Home() {
    return (
      <div className="Home">
          <Header /> 
          <PageComponent />
          <PageComponent2 />
      </div>
    );
  }
  
  export default Home;
  