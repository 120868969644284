import '../static/css/pageComponent2.css';
import windowDimensions from "./getWindowDimensions";
import Product from './produkt'
function Part() {
  return (
    <div className="Part">
        <h2>Lightning Macroeconomics</h2>
        <div className="containerPart">
            <p>In the beginning, Lightning will quickly go through its cycles and likely have multiple cycles per day. The more cycles Lightning completes, the longer it will take to complete new cycles.<br /> <br /> For Lightning Holders this means that early adoption and holding gets rewarded highly, as Lightning Rebases keep compounding and Total Supply keeps getting reduced. After 156 cycles, the Total Supply will be reduced down to 550,000. Once this happened, the protocol has reached its final phase in which fees will automatically go to 0. By holding Lightning through multiple cycles, value and holdings increase exponentially, granting early adopters the most value.
                <br/><br />All of this is done to ensure a long-term store of value which will be integrated into the DeFi ecosystem.
            </p>
            <div className="containerD">
              <Product />
            </div>
        </div>
    </div>
  );
}

export default Part;
