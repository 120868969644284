import "../static/css/footer.css";
import Icons from './Icons'
import { motion } from "framer-motion";

function Footer() {

  return (
    <div className="Footer">
      <motion.div className="container">
          <Icons />
      </motion.div>
    </div>
  );
}

export default Footer;
