import { NavLink } from "react-router-dom";
import "../static/css/desktopNav.css";
import logo from '../static/img/icons/logo.png'

import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function DesktopNav() {
  return (
    <div className="DesktopNav">
      <nav>
        <ul>
          <motion.li
            variants={variants}
          >
            <NavLink
              exact
              activeClassName="navbar_link-active"
              className="navbar_link"
              to="/"
            >
              <img src={logo} alt="Lightening Protocol"/>
            </NavLink>
          </motion.li>
          <motion.li
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="dashboard"
          >
            <NavLink
              exact
              activeClassName="navbar_link-active"
              className="navbar_link"
              to="/dashboard"
            >
              Dashboard
            </NavLink>
          </motion.li>
        </ul>
      </nav>
    </div>
  );
}

export default DesktopNav;
