import React, { Suspense } from 'react';
import { Canvas } from 'react-three-fiber';

const CanvasContainer = ({
  width,
  height,
  position,
  fov,
  children,
  ...rest
}) => {
  return (
    <div 
        {...rest} 
        style={{
            height: height,
            width: width,
        }}>
      <Canvas
        colorManagement
        camera={{
          position,
          fov,
        }}
      >
        <Suspense fallback={null}>{children}</Suspense>
      </Canvas>
    </div>
  );
};

export default CanvasContainer;