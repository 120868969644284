import Home from '../pages/Home'
import Dashboard from '../pages/Dashboard'
import '../static/css/routes.css'

import { Route, Switch } from 'react-router-dom';

function Routes() {
  return (
    <div className="Routes">
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/dashboard' component={Dashboard} />
      </Switch>
    </div>
  );
}

export default Routes;