import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import windowDimensions from './getWindowDimensions'

function Nav() {
    const { width } = windowDimensions();
            if( width >= 780){
               return <DesktopNav />
            } else {
                return <MobileNav />
            }
           
  }
  
  export default Nav;
