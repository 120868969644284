import Model from './model';
import { OrbitControls } from '@react-three/drei';
import CanvasContainer from './canvas-container';
import windowDimensions from "./getWindowDimensions";

const Product = () => {
  const size = windowDimensions();
  return (
    <CanvasContainer height={size.width <= 408 ? 400 : size.width <= 1240 ? 700 : size.width <= 1760 ?  900 : 1300} width={size.width <= 608 ? 300 : size.width <= 1440 ? 500 : size.width <= 1760 ?  800 :  1400} position={[0, 0, 0]} fov={75} className="Product">
      <Model
        scenePath="/formtwo.gltf"
        position={[-2, 15, -24]}
        rotation={[0, 0.004, 0]}
      />
      <OrbitControls />
    </CanvasContainer>
  );
};

export default Product;