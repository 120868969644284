import "../static/css/dashboard.css";
import { motion } from "framer-motion";
import { useState } from "react";

function ToolTipBox({ text, children }) {
  const [isShown, setIsShown] = useState(false);

  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  };
  return (
    <motion.div
      onHoverStart={() => {
        setIsShown(true);
      }}
      onHoverEnd={() => {
        setIsShown(false);
      }}
      className="containerTipBox"
    >
      <motion.div
        className="ToolTipBox"
        transition={{ duration: 0.5, type: "tween" }}
        animate={isShown ? "visible" : "hidden"}
        variants={variants}
      >
        {text}
      </motion.div>
      <div className="children">
        {children}
      </div>
    </motion.div>
  );
}

export default ToolTipBox;
