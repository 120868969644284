import "../static/css/pageComponent.css";
import windowDimensions from "./getWindowDimensions";
import circle from "../static/img/icons/circle.svg";
import fire from "../static/img/icons/fire.svg";
import coins from "../static/img/icons/coins.svg";

import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

function PageComponent() {
  const size = windowDimensions();
  const [show, setShow] = useState(false);
  const pageComp = useRef();
  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShow(true);
        } else setShow(false);
      });
    };
    let options = {
      treshold: 0.9,
      rootMargin: "-250px",
    };

    if(size.width <= 780) {
      options = {
        treshold: 0.2,
        rootMargin: "-40px",
      };
    } else if(size.width <= 700) {
      options = {
        treshold: 0.2,
        rootMargin: "40px",
      };
    }
    const observer = new IntersectionObserver(callback, options);
    observer.observe(pageComp.current);
  }, []);

  const cardContainer = {
    hidden: {
      opacity: 1,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: size.width > 400 ? 0.1 : 0.6,
        staggerChildren: 0.1,
      },
    },
  };
  const cardVariants = {
    hidden: {
      x: size.width > 400 ? 20 : 300,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };
  const content = [
    {
      src: circle,
      heading: "Lightning Cycle",
      text:
        "A full deflationary cycle which, in the beginning, will be multiple times per day and each cycle will burn 1.275% (1.275.000 Tokens) of the total token supply.",
    },
    {
      src: fire,
      heading: "Lightning Squeeze",
      text:
        "The second half of the cycle is called the Lightning Squeeze. Burn rate increases and the total supply burn accelerates, leading to a fast squeeze of the supply.",
    },
    {
      src: coins,
      heading: "Lightning Rebase",
      text:
        "A positive rebase that expands supply by 50 % of the burnt tokens during the current cycle. Each cycle will still burn 1.275 % of the starting total supply but will also rebase the 50 % of burnt tokens among wallets.",
    },
  ];
  return (
    <div ref={pageComp} className="PageComponent">
      <motion.div animate={show ? "visible" : "hidden"} className="container">
    
        <motion.div className="card-container" variants={cardContainer}>
          {content.map((item, i) => {
            return (
              <motion.div className="card" variants={cardVariants} key={i}>
                <div className="imageDiv">
                  <motion.img src={item.src} width="100px" alt="" />
                </div>
                <h3>{item.heading}</h3>
                <p>
                  {item.text}
                </p>
              </motion.div>
            );
          })}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default PageComponent;
