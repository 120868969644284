import "./App.css";

import Routes from "./components/Routes";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

function App() {
  // const mesh = useRef()
  return (
      <div className="App">
        <Nav />
        <Routes />
        {/* <Canvas colorManagement camera={{ position: [0, 0, 120], fov: 70 }}>
          <Suspense fallback={<div>Loading</div>}> */}
          {/* <pointLight position={[10, 10, 10]} /> */}
            
              {/* <group position={[0, 200, 0]}>
              <mesh ref={mesh} position={[0, 35, 0]}>
                <Model />
              </mesh>
            </group> */}
            {/* <Model />
          </Suspense>
        </Canvas> */}
        <Footer />
      </div>
  );
}

export default App