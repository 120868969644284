import { NavLink } from "react-router-dom";
import "../static/css/nav.css";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(32px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};
const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="white"
    strokeLinecap="round"
    {...props}
  />
);

function MobileNav() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <div className="MobileNav">
      <div className="bar"></div>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
          <motion.div className="background" variants={sidebar} />
        <motion.ul variants={variants}>
          <motion.li
            variants={variantsLi}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => toggleOpen()}
          >
            <NavLink
              exact
              activeClassName="navbar_link-active"
              className="navbar_link"
              to="/"
            >
              Home
            </NavLink>
          </motion.li>
          <motion.li
            variants={variantsLi}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => toggleOpen()}
          >
            <NavLink
              exact
              activeClassName="navbar_link-active"
              className="navbar_link"
              to="/dashboard"
            >
              Dashboard
            </NavLink>
          </motion.li>
        </motion.ul>
      </motion.nav> 
      <button onClick={() => toggleOpen()}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
       d={!isOpen ? "M 2 2.5 L 20 2.5" : "M 3 16.5 L 17 2.5"}
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5", opacity: 1 },
          open: { d: "M 3 16.5 L 17 2.5", opacity: 1 }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        style={{opacity: isOpen ? '0' : '1'}}

        transition={{ duration: 0.1 }}
      />
      <Path  
        d={!isOpen ? "M 2 16.346 L 20 16.346" : "M 3 2.5 L 17 16.346"}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
    </div>
  );
}

export default MobileNav;
