import React from 'react';
import { useGLTF, Center } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';
import ModelLights from './model-lights';

const Model = ({ scenePath, position, rotation }) => {
  const gltf = useGLTF(scenePath, true);
  const mesh = React.useRef();
  useFrame(
    () => (
      (mesh.current.rotation.x += rotation[1]),
      (mesh.current.rotation.y += rotation[0])
    ),
  );
  return (
  <Center>
    <mesh 
      ref={mesh} 
      position={position}
      
      scale={ [3, 3, 3]}
      >
      <ModelLights/>
      <primitive object={gltf.scene} dispose={null} />
    </mesh>
  </Center>
  );
};

export default Model;  