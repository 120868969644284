import "../static/css/icons.css";
import medium from "../static/img/icons/medium.svg";
import telegram from "../static/img/icons/telegram.svg";
import paper from "../static/img/icons/paper.svg";
import twitter from "../static/img/icons/twitter.svg";
import bscScan from "../static/img/icons/bscscan.svg";
import pancakeSwap from "../static/img/icons/pancakeSwap.svg";
import litepaper from '../static/img/litepaper/LITEPAPER.final.pdf';

import { motion } from "framer-motion";

function Icons() {

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  return (
    <div className="Icons">
      <motion.div className="container">
        <motion.div 
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href="https://lightningprotocol.medium.com/introducing-lightning-protocol-10932585bb15" target="_blank" rel="noreferrer"><img src={medium} alt="Medium"/></a>
            <p>Medium</p>
        </motion.div>
        <motion.div
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href="http://t.me/lightningprotocol" target="_blank" rel="noreferrer" ><img src={telegram} alt="Telegram"/></a>
            <p>Telegram</p>
        </motion.div>
        <motion.div
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href={litepaper} target="_blank" rel="noreferrer" ><img src={paper} alt="Paper"/></a>
            <p>Litepaper</p>
        </motion.div>
        <motion.div
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x7b9c3df47f3326fbc0674d51dc3eb0f2df29f37f" target="_blank" rel="noreferrer" ><img src={pancakeSwap} alt="PancakeSwap"/></a>
            <p>PancakeSwap</p>
        </motion.div>
        <motion.div
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href="https://bscscan.com/address/0x7b9c3df47f3326fbc0674d51dc3eb0f2df29f37f" rel="noreferrer" target="_blank"><img src={bscScan} alt="BscScan"/></a>
            <p>BscScan</p>
        </motion.div>
        <motion.div
            variants={variants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            >
            <a href="https://twitter.com/lightningDeFi" rel="noreferrer" target="_blank"><img src={twitter} alt="BscScan"/></a>
            <p>Twitter</p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Icons;
