import React, { useState, useEffect } from "react";
import logo from "../static/img/icons/logo.png";
import "../static/css/dashboard.css";
import {
  RadialChart,
  XYPlot,
  LabelSeries,
  VerticalBarSeries,
} from "react-vis";
import { motion } from "framer-motion";
import ToolTipBox from "../components/ToolTipBox";
import windowDimensions from "../components/getWindowDimensions";

let api_url = "https://api.lightningprotocol.finance/stats";
function Dashboard() {
  const size = windowDimensions();
  let [stats, setStats] = useState([]);
  let [isLoaded, setIsLoaded] = useState(false);
  let [err, setErr] = useState(null);

  useEffect(() => {
    const getStats = () => {
      fetch(api_url)
        .then((res) => {
          if (res.status >= 400) {
            throw new Error("Server responds with error!");
          }
          return res.json();
        })
        .then(
          (stats) => {
            setStats(stats);
            setIsLoaded(true);
          },
          (err) => {
            setErr(err);
            setIsLoaded(true);
          }
        );
    };

    getStats();
  }, []);

  const rebase = 637500;
  const cycleData = [
    {
      angle: stats.cycle,
      color: "#ffab40",
      radius: 300,
    },
    {
      angle: 156,
      color: "#161a30",
      radius: 290,
      // label: "156",
    },
  ];
  const radialLabel = [
    { x: 1, y: 0, label: "156", yOffset: size.width <= 1440 ? -10 : -95, 
    style: { fontSize: size.width <= 1440 ?  7 : 20},  },
    {
      x: 1,
      y: 1,
      label: `${stats.cycle}`,
      xOffset: size.width <= 1440 ? -78 : -170,
      yOffset: size.width <= 1440 ? 10 : 15,
      style: { fontSize: size.width <= 1440 ?  7 : 20}, 
    },
  ];
  const supplyData = [
    { x: 1, y: 100000000, color: "#161a30", label: "100,000,000" },
    { x: 2, y: stats.totalBurn, color: "#161a30" },
    { x: 3, y: stats.cycle * rebase, color: "#161a30" },
    { x: 4, y: stats.totalSupply, color: "#ffab40" },
  ];
  const supplyLabel = [
    {
      x: 1,
      y: 1,
      label: "Initial Supply",
      yOffset: size.width <= 1440 ?  13 : 26,
      xOffset: size.width <= 1440 ? 23 : 52,
      style: { fontSize: size.width <= 1440 ?  7 : 17}, 
    },
    {
      x: 1,
      y: 1,
      label: "Total Burned",
      yOffset: size.width <= 1440 ?  13 : 26,
      xOffset: size.width <= 1440 ? -40 : -62,
      style: { fontSize: size.width <= 1440 ?  7 : 17}, 
    },
    {
      x: 1,
      y: 1,
      label: "Rebases",
      yOffset: size.width <= 1440 ?  13 : 26,
      xOffset: size.width <= 1440 ? -111 : -192.5,
      style: { fontSize: size.width <= 1440 ?  7 : 17}, 
    },
    {
      x: 1,
      y: 1,
      label: "Total Supply",
      yOffset: size.width <= 1440 ?  13 : 26,
      xOffset: size.width <= 1440 ? -170 : -290,
      style: { fontSize: size.width <= 1440 ?  7 : 17}, 
    },
  ];
  const widthHeightCharts = size.width <= 1440 ? 300 : 500;
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }

  if (err) {
    return <div> {err.message} </div>;
  } else if (!isLoaded) {
    return (
      <div className="Dashboard">
        <img src={logo} alt="Lightning Protocol" />
        <div> Loading... </div>
      </div>
    );
  } else {
    return (
      <div className="Dashboard">
        <img src={logo} alt="Lightning Protocol" />
        <div className="containerOverview">
          <h3>Overview</h3>
          <div className="container">
            <h4>Current Stats</h4>
            <div className="lighter">
              <h6>Price:</h6>
              <h5>{stats.price}</h5>
            </div>
            <div>
              <h6>Market Cap:</h6>
              <h5>{commafy(Math.round(stats.marketCap))}$</h5>
            </div>
            <div className="lighter">
              <h6>Total Supply:</h6>
              <h5>{commafy(Math.round(stats.totalSupply))}</h5>
            </div>
            <div>
              <h6>Burn Fee:</h6>
              <h5>{stats.burnFee}%</h5>
            </div>
          </div>
          <div className="container">
            <h4>Cycle {stats.cycle} of 156</h4>
            <div className="lighter">
              <h6>Initial Supply:</h6>
              <h5>100,000,000</h5>
            </div>
            <div>
              <h6>Tokens traded during Cycle:</h6>
              <h5>{commafy(Math.round(stats.tradedCycle))}</h5>
            </div>
            <div className="lighter">
              <h6>Total Burn:</h6>
              <h5>{commafy(Math.round(stats.totalBurn))}</h5>
            </div>
            <div>
              <h6>Tokens burned during Cycle:</h6>
              <h5>{commafy(Math.round(stats.burnCycle))}</h5>
            </div>
          </div>
        </div>
        <div className="charts">
          <div className="containerChart">
            <h4>Cycles</h4>
            <ToolTipBox
              text={`${156 - stats.cycle} cycles left`}
              children={
                <motion.div
                  whileHover={{ scale: 0.8 }}
                  whileTap={{ rotate: 45 }}
                >
                  <XYPlot
                    width={widthHeightCharts}
                    height={widthHeightCharts}
                    className="XYPlotRadial"
                  >
                    <RadialChart
                      data={cycleData}
                      width={size.width <= 1440 ? widthHeightCharts - 10 : widthHeightCharts - 16}
                      height={size.width <= 1440 ? widthHeightCharts - 10 : widthHeightCharts - 16}
                      colorType="literal"
                      className="radialChart"
                    />
                    <LabelSeries
                      animation
                      allowOffsetToBeReversed
                      data={radialLabel}
                      className="labelsRadial"
                    />
                  </XYPlot>
                </motion.div>
              }
            ></ToolTipBox>
          </div>
          <div className="containerChart hugeText">
            <h4>Supply</h4>
            <ToolTipBox
              text={
                <div>
                  <div>Initial: 100,000,000</div>
                  <div>
                    - Total burned: {commafy(Math.round(stats.totalBurn))}
                  </div>
                  <div>
                    - Rebase * Cycle: {stats.cycle} *{" "}
                    {commafy(Math.round(rebase))} =
                    {commafy(Math.round(stats.cycle * rebase))}
                  </div>
                  <div>
                    = Total Supply: {commafy(Math.round(stats.totalSupply))}
                  </div>
                </div>
              }
              children={
                <motion.div whileHover={{ scale: 0.8 }}>
                  <XYPlot width={widthHeightCharts} height={widthHeightCharts}>
                    <LabelSeries
                      animation
                      allowOffsetToBeReversed
                      data={supplyLabel}
                      className="labelsRadial"
                    />
                    <VerticalBarSeries
                      data={supplyData}
                      colorType="literal"
                      className="radialChart"
                    ></VerticalBarSeries>
                  </XYPlot>
                </motion.div>
              }
            ></ToolTipBox>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
