import { motion, useViewportScroll, useTransform } from "framer-motion";
import "../static/css/header.css";
import Platzhalter from "../static/img/logo.svg";
import logo from "../static/img/icons/logo.png";
import { useState } from "react";
import Icons from './Icons'
import windowDimensions from "./getWindowDimensions";

function Header() {
  const { width } = windowDimensions();
  const [ffLayer, setFfLayer] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const scaleAnim = useTransform(scrollYProgress, [0, 0.3, 0.6], [1, 1.5, 2]);
  const yPosAnim = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -145, -290]);
  const yPosAnim2 = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -100, -220]);
  const yPosAnim3 = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -95, -140]);
  const zRotAnim = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -142, -260]);
  const zRotAnim2 = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -95, -190]);
  const zRotAnim3 = useTransform(scrollYProgress, [0, 0.3, 0.6], [0, -120, -310]);
  const contentXAnim = useTransform(scrollYProgress, [0, 0.4, 0.6], [0, -60, -120]);

  scrollYProgress.onChange((x) => {
    setFfLayer(x > 0.4 ? -1 : 0);
  });
  return (
    <div className="Header">
      <div className="headerContainer">
        <motion.div
          className="content"
          style={
            {
              y: contentXAnim,
            }
          }
        >
          
          <img src={logo} className={width <= 736 || width >= 1440 ? 'logo' : 'logo none'} alt="lighning protocol"></img>
        <Icons />
        </motion.div>
        <motion.div
          className="imageContainer"
        >
          <div className="svgContainer">
            <motion.img 
              animate={{
                x: 0,
                y: 0,                
                rotate: 360,
              }}
              transition={{
                repeat: Infinity,
                ease: "linear",
                duration: 34,
              }}
              src={Platzhalter} 
              className={width > 1250 ? "platzhalter" : "platzhalter smaller"}>

              </motion.img>
          </div>
          <div>
            <motion.svg
              id="Ebene_1"
              data-name="Ebene 1"
              className="animtedBackground"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox={width > 1250 ? "0 0 75 75" : "0 0 90 90"}
              fill="url(#Unbenannter_Verlauf_10)"
              initial={{
                x: 0,
                y: 0,
                scale: 1,
              }}
              animate={{
                x: 0,
                y: 0,
                scale: 1.05,
              }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
              }}
              style={{
                scale: scaleAnim,
                x: width <= 736 ? yPosAnim3 : width <= 1009 ? yPosAnim2 : yPosAnim ,
                y: width <= 736 ? zRotAnim3 : width <= 1009 ? zRotAnim2 : zRotAnim,
              }}
            >
              <defs>
                <radialGradient
                  id="Unbenannter_Verlauf_10"
                  cx="50%"
                  cy="50%"
                  r="50.02"
                  gradientUnits="userSpaceOnUse"
                >
                  <motion.stop
                    stopColor="#b5a7fd"
                    animate={{
                      stopColor: [
                        "#b5a7fd",
                        "#FFF9DA",
                        "#E7FFF7",
                        "#FFC6A8",
                        "#f3d2c6",
                        "#F3F2F2",
                        "#b5a7fd",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      ease: "linear",
                      duration: 14,
                    }}
                    offset="25%"
                  />
                  <motion.stop
                    stopColor="#b5a7fd"
                    animate={{
                      stopColor: [
                        "#b5a7fd",
                        "#FFF9DA",
                        "#FFC6A8",
                        "#f3d2c6",
                        "#b5a7fd",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      ease: "linear",
                      duration: 14,
                    }}
                    offset="50%"
                  />
                  <motion.stop
                    stopColor="#f3d2c6"
                    animate={{
                      stopColor: [
                        "#b5a7fd",
                        "#FFF9DA",
                        "#E7FFF7",
                        "#90b5ff",
                        "#b6fce3",
                        "#f3d2c6",
                        "#b5a7fd",
                      ],
                    }}
                    transition={{
                      repeat: Infinity,
                      ease: "linear",
                      duration: 14,
                    }}
                    offset="75%"
                    stopOpacity="0.25"
                  />
                  <motion.stop
                    stopColor="#f3d2c6"
                    animate={{
                      stopColor: ["#c6dbf3", "#b5a7fd", "#b6fce3", "#fcf4cc"],
                    }}
                    transition={{
                      repeat: Infinity,
                      ease: "linear",
                      duration: 14,
                    }}
                    offset="100%"
                    stopOpacity="0"
                  />
                </radialGradient>
              </defs>
              <circle className="cls-1" cx="50%" cy="50%" r="60.02" />
            </motion.svg>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Header;
