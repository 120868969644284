import React from 'react';

const ModelLights = () => (
  <>
    <directionalLight position={[10, 10, 5]} intensity={2} />
    <directionalLight position={[-10, -10, -5]} intensity={1} />
    <ambientLight intensity={0.5} />
      <spotLight
        intensity={0.6}
        position={[20, 10, 10]}
        angle={0.2}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow
      />
  </>
);

export default ModelLights;